.container {
	position: sticky;
	top: 0;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: .5rem 0;
	z-index: 9;

	@media (min-width: 568px) {
		margin: 2rem 0;
	}

	@media (min-width: 1200px) {
		margin: 4rem 0;
	}
}
